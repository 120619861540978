.main_profile {
  position: relative;
  display: flex;
  flex-direction: column;
}

.profile_cover {
  height: 15.5rem;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  color: grey;
}

.profile_description {
  position: relative;
  /* height: 15.5rem; */
  max-width: 100%;
}

.profile_description > .title {
  margin-top: 2rem;
}

/* .profile_description > .description {
  margin-top: 0.5rem;
} */

/* .member_name {
  display: flex;
  justify-content: center;
}

.member_name > p {
  font-size: 1rem;
  position: absolute;
  top: .5rem;
  margin: 0;
} */

/* .member_name_text > p {
  top: -3px;
} */

.profile_img {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  margin-left: -50px;
  top: 170px;
  /* top: 50%; */
  /* margin-top: -18px; /*-50px to center the image in workbox center */
}

.wb_body_btn {
  min-width: 66px;
}

.wb_menu {
  background-color: #F8F7F7;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  text-align: center;
  margin: 5px auto;
  width: 300px;
}

.wb_menu_md {
  width: 90%;
}

.wb_menu_md > ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.wb_menu_md > ul > li {
  /* margin: 1px; */
  flex: 0 33%;
}

.wb_menu > ul > li:hover {
  cursor: pointer;
}

/* .left_menu, .right_menu {
  padding-left: 10px;
  padding-right: 10px;
} */