/* .ab-slide-card {
  width: 266.67px;
  height: 150px;
  overflow: hidden;
  background-color: black;
  backface-visibility: hidden;
  cursor: pointer;
  display: block;
  position: relative;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 4px;

  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;

  display: flex;
  justify-content: center;
  align-items: center;
}


.ab-slide-card:hover:not(.ab-slide-selected) {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;

  -moz-box-shadow: inset 0 0 5px 5px #888;
  -webkit-box-shadow: inset 0 0 5px 5px#888;
  box-shadow: inset 0 0 5px 5px #888;

  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.ab-slide-card:not(.ab-slide-selected)::after {
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 4px;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
  border-image: initial;
  transition: border 300ms ease-out 0s;
}

.ab-slide-card:hover:not(.ab-slide-selected)::after {
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: initial;
}

.ab-slide-selected {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  -moz-box-shadow: inset 0 0 5px 5px #888;
  -webkit-box-shadow: inset 0 0 5px 5px#888;
  box-shadow: inset 0 0 5px 5px #888;
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: initial;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.ab-project img {
  max-width: 100%;
  height: auto;
}
*/

.ab-slide-remove {
  position: absolute;
  top: 4px;
  right: 4px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.4);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  z-index: 100;
}

.ab-slide-remove:hover {
  background-color: #d9534f;
}
