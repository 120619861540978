.uppy-Root {
  display: flex;
  justify-content: center;
}

.aib-uppy * .uppy-Dashboard-files {
  padding: 0;
}

.aib-uppy * .uppy-Dashboard-Item-fileInfoAndButtons {
  position: absolute;
  top: 71px;
  left: 5px;
  padding: 5px;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
}


.aib-uppy * .uppy-Dashboard-Item:hover .uppy-Dashboard-Item-fileInfoAndButtons {
  display: block;
}

.aib-uppy * .uppy-Dashboard-Item-fileInfoAndButtons,
.aib-uppy * .uppy-Dashboard-Item-status {
  color: white;
}

.aib-uppy * .uppy-Dashboard-Item,
.aib-uppy * .uppy-Dashboard-Item-preview {
  margin: 5px;
  width: 175px;
  height: 109.38px;
}

.aib-uppy * .uppy-DashboardContent-bar,
.aib-uppy * .uppy-StatusBar {
  display: none;
}

.wb_card_md * .aib-uppy * .uppy-Dashboard-AddFiles {
  justify-content: start;
  padding-top: 2.5rem;
}

/* .aib-uppy * .uppy-Dashboard-files > div[role=presentation] {
  height: auto;
} */

/* .uppy-med * .uppy-DashboardContent-bar,
.uppy-med * .uppy-StatusBar,
.uppy-med * .uppy-Dashboard-Item-fileInfoAndButtons {
  display: none;
} */

