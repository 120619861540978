/* .wb_container {
  margin: 0.25rem auto!important;
  max-width: 75rem;
} */

.wb_card_header_left, .wb_card_header_right {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.wb_card_header_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  /* text-align: center!important; */
}

.wb_card_header_space {
  flex: 1;
  /* border: 2px solid red; */
  height: 100%;
}

.wb_card_header_space:hover {
  cursor: pointer;
}

.wb_card_header_icon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #D3D3E1; /*provided by brad*/
}
.wb_card_header_icon > svg {
  margin-left: .4rem;
  margin-right: .4rem;
  transition: all .1s;
  transform-origin: 50% 50%;
}
.wb_card_header_icon > svg:hover {
  cursor: pointer;
  transform: scale(1.14);
}

.wb_card {
  overflow: hidden;
  max-height: 38rem;
  /* -webkit-transition: max-height 0.1s;
  -moz-transition: max-height 0.1s;
  -o-transition: max-height 0.1s;
  transition: max-height 0.1s; */
}

.wb_card_sm {
  /* height: 2.25rem; */
  /* max-height: 2.25rem;
  -webkit-transition: max-height 0.1s;
  -moz-transition: max-height 0.1s;
  -o-transition: max-height 0.1s;
  transition: max-height 0.1s; */
  display: none;
}

.wb_card_md {
  max-height: 9rem;
  -webkit-transition: max-height 0.1s;
  -moz-transition: max-height 0.1s;
  -o-transition: max-height 0.1s;
  transition: max-height 0.1s;
}

.wb_card_body {
  padding: 6px 0 0;
}

.wb_card_header {
  padding: 0 0.5rem;
  min-height: 2.25rem;
  background: #e0e0e0;
  display: flex;
  justify-content: space-between;
  border-bottom: 0;
}

.wb_card_header_title {
  display: flex;
  justify-content: center;
}

.wb_card_header_title > p {
  font-size: 1rem;
  /* position: absolute;
  top: 5px; */
  margin: 0; 
  padding: .15em .5em;
  /* max-width: 14rem; */
}

.c_pointer {
  cursor: pointer;
}

/* .title_edit_icon { */
  /* position: absolute; */
  /* cursor: pointer; */
  /* top: 50%; */
  /* right: 50%;
  width: 20px; */
/* } */

.title_edit_icon > svg {
  vertical-align: text-top;
}

.title_text {
  display: inline-block;
  max-width: 14rem;
  line-height: 1.3;
}