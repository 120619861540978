@import url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css);
.aib-header {
  height: 55px;
  /* display: flex; */
  position: relative;
  min-width: 100%;
}

.logo {
  line-height: 1.2;
  width: 174px;
  position: relative;
  display: block;
}

.explore {
  font-weight: 600;
}

.header-menu-link:hover {
  color: blue;
  text-decoration: underline;
}

.header-menu-link-icon {
  padding: .7rem 1rem 0
}


.headbox {
  height: 58px;
  width: 100%;
  display: block;
  position: static;
  z-index: 86;

}
#box {
  position: relative;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: rgba(149,207,37,1);
  clear: none;
  display: block;
  margin-left: 106px;
  margin-top: 8px;
}

#Something {
  text-align: center;
  position: absolute;
  left: 282px;
  top: 20px;
  overflow: visible;
  width: 188px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #CAC8C8;
  margin-bottom: 24px;
}
#Someone {
  position: absolute;
  left: 282px;
  top: 24px;
  overflow: visible;
  width: 188px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  color: rgba(224,224,224,1.00);
}

.boxshape {
-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));

  position: absolute;
  overflow: visible;
  width: 70px;
  height: 67px;
  left: 0px;
  top: 6px;
  margin-top: -43px;
  margin-left: -7px;
}

.recshape {
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
  position: absolute;
  overflow: visible;
  width: 84px;
  height: 55px;
  margin-left: 102px;
  margin-top: 7px;
}
#art {
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: rgba(149,207,37,1);
  margin-left: 32px;
  margin-top: -4px;
}
#is {
  opacity: 0.81;
  position: absolute;
  overflow: visible;
  width: 18px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: rgba(149,207,37,1);
  margin-left: 91px;
  margin-top: 10px;
}
.timeline-slider .slick-list .slick-track .slick-slide {
  padding: 10px;
  border-radius: 6px;
}

.timeline-slider-md .slick-list .slick-track .slick-slide {
  padding: 6px 4px;
}

.timeline-slider .slick-prev:before, .slick-next:before  {
  content: '' !important;
}

.timeline-slider .slick-prev {
  left: -4px;
  height: 150px;
  width: 35px;
  color: #fff;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.timeline-slider .slick-next {
  right: -4px;
  height: 150px;
  width: 35px;
  color: #fff;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.timeline-slider-md .slick-prev {
  left: -33px;
  height: 68px;
  background-color: transparent;
  background-color: initial;
}

.timeline-slider-md .slick-next {
  right: -37px;
  height: 68px;
  background-color: transparent;
  background-color: initial;
}

.timeline-slider .slick-prev svg {
  height: 100%;
  width: 150%;
  margin: 1px -10px;
}

.timeline-slider .slick-next svg {
  height: 100%;
  width: 150%;
  margin: 1px -10px;
}

.timeline-slider-md .slick-prev svg {
  width: 100%;
  margin: 2px 0;
}

.timeline-slider-md .slick-next svg {
  width: 100%;
  margin: 2px 0;
}

.timeline-slider .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)); */
}
.timeline-slider .slick-prev:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)); */
}

.timeline-slider-md .slick-prev:hover {
  color: #000;
  background-color: inherit;
}

.timeline-slider-md .slick-next:hover {
  color: #000;
  background-color: inherit;
}


.timeline-slider .slick-track {
  display: flex;
}






.slider-card {
  /* width: 100%; */
  width: 266.67px;
  height: 150px;
  overflow: hidden;
  background-color: black;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  cursor: pointer;
  display: block;
  position: relative;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 4px;
  box-shadow: 0 10px 6px -6px #777;

  /* align center the smaller items in the slider box */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-card-md {
  width: 120px;
  height: 67.50px;
}

.slider-card:hover:not(.slider-card-active) {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  box-shadow: inset 0 0 5px 5px #888;
  box-shadow: 0 10px 6px -6px #777;
}

.slider-card:not(.slider-card-active)::after {
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 4px;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
  border-image: none;
  border-image: initial;
  transition: border 300ms ease-out 0s;
}

.slider-card:hover:not(.slider-card-active)::after/*, .slider-card:focus::after*/ {
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: none;
  border-image: initial;
}

.slider-card-active {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  box-shadow: inset 0 0 5px 5px #888;
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: none;
  border-image: initial;
  box-shadow: 0 10px 6px -6px #777;
  /* transition: border 300ms ease-out 0s; */
}

.slider-card-image img {
  max-width: 100%;
  height: auto;
}
/*
.ab-slide-remove {
  position: absolute;
  top: 4px;
  right: 4px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.4);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  z-index: 100;
}

.ab-slide-remove:hover {
  background-color: #d9534f;
} */

/* .wb_container {
  margin: 0.25rem auto!important;
  max-width: 75rem;
} */

.wb_card_header_left, .wb_card_header_right {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.wb_card_header_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  /* text-align: center!important; */
}

.wb_card_header_space {
  flex: 1 1;
  /* border: 2px solid red; */
  height: 100%;
}

.wb_card_header_space:hover {
  cursor: pointer;
}

.wb_card_header_icon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #D3D3E1; /*provided by brad*/
}
.wb_card_header_icon > svg {
  margin-left: .4rem;
  margin-right: .4rem;
  transition: all .1s;
  transform-origin: 50% 50%;
}
.wb_card_header_icon > svg:hover {
  cursor: pointer;
  transform: scale(1.14);
}

.wb_card {
  overflow: hidden;
  max-height: 38rem;
  /* -webkit-transition: max-height 0.1s;
  -moz-transition: max-height 0.1s;
  -o-transition: max-height 0.1s;
  transition: max-height 0.1s; */
}

.wb_card_sm {
  /* height: 2.25rem; */
  /* max-height: 2.25rem;
  -webkit-transition: max-height 0.1s;
  -moz-transition: max-height 0.1s;
  -o-transition: max-height 0.1s;
  transition: max-height 0.1s; */
  display: none;
}

.wb_card_md {
  max-height: 9rem;
  transition: max-height 0.1s;
}

.wb_card_body {
  padding: 6px 0 0;
}

.wb_card_header {
  padding: 0 0.5rem;
  min-height: 2.25rem;
  background: #e0e0e0;
  display: flex;
  justify-content: space-between;
  border-bottom: 0;
}

.wb_card_header_title {
  display: flex;
  justify-content: center;
}

.wb_card_header_title > p {
  font-size: 1rem;
  /* position: absolute;
  top: 5px; */
  margin: 0; 
  padding: .15em .5em;
  /* max-width: 14rem; */
}

.c_pointer {
  cursor: pointer;
}

/* .title_edit_icon { */
  /* position: absolute; */
  /* cursor: pointer; */
  /* top: 50%; */
  /* right: 50%;
  width: 20px; */
/* } */

.title_edit_icon > svg {
  vertical-align: text-top;
}

.title_text {
  display: inline-block;
  max-width: 14rem;
  line-height: 1.3;
}
.uppy-Root {
  display: flex;
  justify-content: center;
}

.aib-uppy * .uppy-Dashboard-files {
  padding: 0;
}

.aib-uppy * .uppy-Dashboard-Item-fileInfoAndButtons {
  position: absolute;
  top: 71px;
  left: 5px;
  padding: 5px;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
}


.aib-uppy * .uppy-Dashboard-Item:hover .uppy-Dashboard-Item-fileInfoAndButtons {
  display: block;
}

.aib-uppy * .uppy-Dashboard-Item-fileInfoAndButtons,
.aib-uppy * .uppy-Dashboard-Item-status {
  color: white;
}

.aib-uppy * .uppy-Dashboard-Item,
.aib-uppy * .uppy-Dashboard-Item-preview {
  margin: 5px;
  width: 175px;
  height: 109.38px;
}

.aib-uppy * .uppy-DashboardContent-bar,
.aib-uppy * .uppy-StatusBar {
  display: none;
}

.wb_card_md * .aib-uppy * .uppy-Dashboard-AddFiles {
  justify-content: start;
  padding-top: 2.5rem;
}

/* .aib-uppy * .uppy-Dashboard-files > div[role=presentation] {
  height: auto;
} */

/* .uppy-med * .uppy-DashboardContent-bar,
.uppy-med * .uppy-StatusBar,
.uppy-med * .uppy-Dashboard-Item-fileInfoAndButtons {
  display: none;
} */


.profile-workbox_main_profile__XYimy {
  position: relative;
  display: flex;
  flex-direction: column;
}

.profile-workbox_profile_cover__1WbPT {
  height: 15.5rem;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  color: grey;
}

.profile-workbox_profile_description__-Eerk {
  position: relative;
  /* height: 15.5rem; */
  max-width: 100%;
}

.profile-workbox_profile_description__-Eerk > .profile-workbox_title__31IDU {
  margin-top: 2rem;
}

/* .profile_description > .description {
  margin-top: 0.5rem;
} */

/* .member_name {
  display: flex;
  justify-content: center;
}

.member_name > p {
  font-size: 1rem;
  position: absolute;
  top: .5rem;
  margin: 0;
} */

/* .member_name_text > p {
  top: -3px;
} */

.profile-workbox_profile_img__71qNk {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  margin-left: -50px;
  top: 170px;
  /* top: 50%; */
  /* margin-top: -18px; /*-50px to center the image in workbox center */
}

.profile-workbox_wb_body_btn__2C64X {
  min-width: 66px;
}

.profile-workbox_wb_menu__1wboB {
  background-color: #F8F7F7;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  text-align: center;
  margin: 5px auto;
  width: 300px;
}

.profile-workbox_wb_menu_md__2_k-k {
  width: 90%;
}

.profile-workbox_wb_menu_md__2_k-k > ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.profile-workbox_wb_menu_md__2_k-k > ul > li {
  /* margin: 1px; */
  flex: 0 1 33%;
}

.profile-workbox_wb_menu__1wboB > ul > li:hover {
  cursor: pointer;
}

/* .left_menu, .right_menu {
  padding-left: 10px;
  padding-right: 10px;
} */
.curtain {
  position: absolute;
  top: 0; 
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100
}
/* .ab-slide-card {
  width: 266.67px;
  height: 150px;
  overflow: hidden;
  background-color: black;
  backface-visibility: hidden;
  cursor: pointer;
  display: block;
  position: relative;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 4px;

  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;

  display: flex;
  justify-content: center;
  align-items: center;
}


.ab-slide-card:hover:not(.ab-slide-selected) {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;

  -moz-box-shadow: inset 0 0 5px 5px #888;
  -webkit-box-shadow: inset 0 0 5px 5px#888;
  box-shadow: inset 0 0 5px 5px #888;

  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.ab-slide-card:not(.ab-slide-selected)::after {
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 4px;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
  border-image: initial;
  transition: border 300ms ease-out 0s;
}

.ab-slide-card:hover:not(.ab-slide-selected)::after {
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: initial;
}

.ab-slide-selected {
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  -moz-box-shadow: inset 0 0 5px 5px #888;
  -webkit-box-shadow: inset 0 0 5px 5px#888;
  box-shadow: inset 0 0 5px 5px #888;
  border-width: 4px;
  border-style: solid;
  border-color: #1abc9c;
  border-image: initial;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.ab-project img {
  max-width: 100%;
  height: auto;
}
*/

.ab-slide-remove {
  position: absolute;
  top: 4px;
  right: 4px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.4);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  z-index: 100;
}

.ab-slide-remove:hover {
  background-color: #d9534f;
}


*,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.login-wrap{
  margin: auto;
  margin-top: 10px;
	width:100%;
	/* margin-left: 35%; */
	max-width:525px;
	min-height:670px;
	position:absolute;
	background:url(https://raw.githubusercontent.com/khadkamhn/day-01-login-form/master/img/bg.jpg) no-repeat center;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:90px 70px 50px 70px;
	background:rgba(40,57,101,.9);
}
.login-html .sign-in-htm,
.login-html .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	-webkit-backface-visibility:hidden;
	        backface-visibility:hidden;
	transition:all .4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
	display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-html .tab{
	font-size:22px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 15px 10px 0;
	display:inline-block;
	border-bottom:2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	width:100%;
	color:#fff;
	display:block;
}
.login-form .group .input,
.login-form .group .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(255,255,255,.1);
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label{
	color:#aaa;
	font-size:12px;
}
.login-form .group .button{
	background:#1161ee;
}
.login-form .group label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-form .group .check:checked + label .icon{
	background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
	transform:rotate(0);
}

.hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgba(255,255,255,.2);
}
.foot-lnk{
	text-align:center;
}
.fabs {
    position: absolute;
    z-index: 1175;
    display: block;
    bottom: 0px;
    float: right;
    right: 0px;
}

.fab {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  text-align: center;
  color: #f0f0f0;
  margin: 25px auto 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
  cursor: pointer;
  transition: all .1s ease-out;
  position: relative;
  z-index: 1998;
  overflow: hidden;
  background: #42a5f5;
}

.fab > i {
  font-size: 2em;
  line-height: 55px;
  transition: all .2s ease-in-out;
}

.fab:not(:last-child) {
  width: 0;
  height: 0;
  margin: 20px auto 0;
  opacity: 0;
  visibility: hidden;
  line-height: 40px;
}

.fab:not(:last-child) > i {
  font-size: 1.4em;
  line-height: 40px;
}

.fab:not(:last-child).is-visible {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10;
    opacity: 1;
    visibility: visible;
}

.fab:nth-last-child(1) {
  transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
  transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
  transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
  transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
  transition-delay: 80ms;
}

.fab(:last-child):active,
.fab(:last-child):focus,
.fab(:last-child):hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}
/*Chatbox*/

.chat {
	z-index: 1998;
    position: relative;
    width: 243px;
    font-size: 12px;
    line-height: 22px;
    font-family: 'Roboto';
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    /* opacity: 0; */
    box-shadow: 1px 1px 100px 2px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
    transition: all .2s ease-in-out;
    float: right;
    clear: none;
    display: block;
    max-width: 305px;
}

.chat_fullscreen {
	z-index: 1998;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    padding-top: 0px;
    margin-top: -5px;
    width: 225px;
  }
.chat_header {
    /* margin: 10px; */
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #f3f3f3;
    height: 59px;
    background-color: #25282E;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 8px;
}
.chat_header2 {
    /* margin: 10px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: 0px;
    margin-bottom: -8px;
}
.chat_header .span {
  float:right;
}

.chat_fullscreen_loader {
  display: none;
    float: right;
    cursor: pointer;
    /* margin: 10px; */
    font-size: 20px;
    opacity: 0.5;
    /* padding: 20px; */
    margin: -10px 10px;
}

.chat.is-visible {
  opacity: 1;
  -webkit-animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
}
.is-hide{
  opacity: 0
}

.chat_option {
  float: left;
  font-size: 15px;
  list-style: none;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: relative;
  margin-right: 10px;
      letter-spacing: 0.5px;
      font-weight: 400
}


.chat_option img {
    border-radius: 50%;
    width: 55px;
    float: left;
    margin-top: -6px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    border: 4px solid rgba(0, 0, 0, 0.21);
}

.change_img img{
    width: 35px;
    margin: 0px 20px 0px 20px;
}
.chat_option .agent {
  font-size: 12px;
    font-weight: 300;
}
.chat_option .online{
      opacity: 0.4;
    font-size: 11px;
    font-weight: 300;
}
.chat_color {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px;
  float: left;
}


.chat_body {
  background: #fff;
  width: 100%;

  display: inline-block;
  text-align: center;
    overflow-y: auto;

}
#chat_body{
    height: 414px;
}
.chat_login p,.chat_body li, .chat_body p, .chat_body a{
    -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
}
.chat_body p {
  padding: 20px;
  color: #888
}
.chat_body a {
  width: 10%;
  text-align: center;
  border: none;
  box-shadow: none;
  line-height: 40px;
  font-size: 15px;
}



.chat_field {
  position: relative;
  margin: 5px 0 5px 0;
  width: 50%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
  outline: none;
  display: inline-block;
}

.chat_field.chat_message {
  height: 30px;
  resize: none;
      font-size: 13px;
    font-weight: 400;
}
.chat_category{
  text-align: left;
}

.chat_category{
  margin: 20px;
  background: rgba(0, 0, 0, 0.03);
  padding: 10px;
}

.chat_category ul li{
    width: 80%;
    height: 30px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    font-size: 13px;
    cursor: pointer;
    line-height: 30px;
    color: #888;
    text-align: center;
}

.chat_category li:hover{
    background: #83c76d;
    color: #fff;
}
.chat_category li.active{
    background: #83c76d;
    color: #fff;
}

.tags{
 margin: 20px;
    bottom: 0px;
    display: block;
    width: 120%
}
.tags li{
    padding: 5px 10px;
    border-radius: 40px;
    border: 1px solid rgb(3, 117, 208);
    margin: 5px;
    display: inline-block;
    color: rgb(3, 117, 208);
    cursor: pointer;

}
.fab_field {
  width: 100%;
  display: inline-block;
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}
.fab_field2 {
    bottom: 0px;
    position: absolute;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
        z-index: 999;
  }

.fab_field a {
  display: inline-block;
  text-align: center;
}

#fab_camera {
  float: left;
  background: rgba(0, 0, 0, 0);
}

#fab_send {
  float: right;
  background: rgba(0, 0, 0, 0);
}

.fab_field .fab {
  width: 35px;
  height: 35px;
  box-shadow: none;
  margin: 5px;
}

.fab_field .fab>i {
  font-size: 1.6em;
  line-height: 35px;
  color: #bbb;
}
.fab_field .fab>i:hover {
  color: #42a5f5;
}
.chat_conversion {

}

.chat_converse {
  position: relative;
    background: #fff;
    margin: 6px 0 0px 0;
    height: 300px;
    min-height: 0;
    font-size: 12px;
    line-height: 18px;
    overflow-y: auto;
    width: 100%;
    float: right;
    padding-bottom: 100px;
}
.chat_converse2{
      height: 100%;
    max-height: 800px
}
.chat_list {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.chat_list .chat_list_item {
  opacity: 0;
  visibility: hidden;
}

.chat .chat_converse .chat_msg_item {
  position: relative;
  margin: 8px 0 15px 0;
  padding: 8px 10px;
  max-width: 60%;
  display: block;
  word-wrap: break-word;
  border-radius: 3px;
  -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  clear: both;
  z-index: 999;
}
.status {
    margin: 45px -50px 0 0;
    float: right;
    font-size: 11px;
    opacity: 0.3;
}
.status2 {
    margin: -10px 20px 0 0;
    float: right;
    display: block;
    font-size: 11px;
    opacity: 0.3;
}
.chat .chat_converse .chat_msg_item .chat_avatar {
  position: absolute;
  top: 0;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin .chat_avatar {
  left: -52px;
 /*-- background: rgba(0, 0, 0, 0.03);--*/
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user .chat_avatar {
  right: -52px;
  background: rgba(0, 0, 0, 0.6);
}

.chat .chat_converse .chat_msg_item .chat_avatar, .chat_avatar img{
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin {
  margin-left: 60px;
  float: left;
  background: rgba(0, 0, 0, 0.03);
  color: #666;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user {
  margin-right: 20px;
  float: right;
  background: #42a5f5;
  color: #eceff1;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin:before {
  content: '';
  position: absolute;
  top: 15px;
  left: -12px;
  z-index: 998;
  border: 6px solid transparent;
  border-right-color: rgba(255, 255, 255, .4);
}

.chat_form .get-notified label{
    color: #077ad6;
    font-weight: 600;
    font-size: 11px;
}

input {
  position: relative;
  width: 90%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  padding: 5px;
  border-radius: 3px;
}
.chat_form .get-notified input {
  margin: 2px 0 0 0;
  border: 1px solid #83c76d;
}
.chat_form .get-notified i {
    background: #83c76d;
    width: 30px;
    height: 32px;
    font-size: 18px;
    color: #fff;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    margin: 2px 0 0 -30px;
    position: absolute;
    border-radius: 3px;
}
.chat_form .message_form {
  margin: 10px 0 0 0;
}
.chat_form .message_form input{
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
}
.chat_form .message_form textarea{
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
  position: relative;
  width: 90%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  padding: 5px;
  border-radius: 3px;
}
.chat_form .message_form button{
    margin: 5px 0 5px 0;
    border: 1px solid #e0e0e0;
    position: relative;
    width: 95%;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    outline: none;
    background: #fff;
    display: inline-block;
    resize: none;
    padding: 5px;
    border-radius: 3px;
    background: #83c76d;
    cursor: pointer;
}
strong.chat_time {
  padding: 0 1px 1px 0;
  font-weight: 500;
  font-size: 8px;
  display: block;
}

/*Chatbox scrollbar*/

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
/*Element state*/

.is-active {
  transform: rotate(180deg);
  transition: all 1s ease-in-out;
}

.is-float {
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}

.is-loading {
  display: block;
  -webkit-animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
  animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
}



/*Animation*/

@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes load {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes load {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
/* SMARTPHONES PORTRAIT */

@media only screen and (min-width: 300px) {
  .chat {
    width: 277px;
  }
}
/* SMARTPHONES LANDSCAPE */

@media only screen and (min-width: 480px) {
  .chat_field {
    width: 65%;
  }
}
/* TABLETS PORTRAIT */

@media only screen and (min-width: 768px) {
}
/* TABLET LANDSCAPE / DESKTOP */

@media only screen and (min-width: 1024px) {
  .chat {
    width: 260px;
  }
  .chat_field {
    width: 65%;
  }
}
/*Color Options*/



.blue .fab {
  background: #42a5f5;
  color: #fff;
}



.blue .chat {
  background: #42a5f5;
  color: #999;
}


/* Ripple */

.ink {
  display: block;
  position: absolute;
  background: rgba(38, 50, 56, 0.4);
  border-radius: 100%;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  webkit-transform: scale(0);
  transform: scale(0);
}
/*animation effect*/

.ink.animate {
  -webkit-animation: ripple 0.5s ease-in-out;
          animation: ripple 0.5s ease-in-out;
}

@-webkit-keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/
  
  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    transform: scale(5);
  }
}

@keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/
  
  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    transform: scale(5);
  }
}
::-webkit-input-placeholder { /* Chrome */
  color: #bbb;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #bbb;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #bbb;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: #bbb;
}

body {
  overflow-x: hidden;
  height: 100vh;
}
.fr-wrapper > div > p {
  margin-bottom: 0;
}
svg {
  vertical-align: unset;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
