.aib-header {
  height: 55px;
  /* display: flex; */
  position: relative;
  min-width: 100%;
}

.logo {
  line-height: 1.2;
  width: 174px;
  position: relative;
  display: block;
}

.explore {
  font-weight: 600;
}

.header-menu-link:hover {
  color: blue;
  text-decoration: underline;
}

.header-menu-link-icon {
  padding: .7rem 1rem 0
}


.headbox {
  height: 58px;
  width: 100%;
  display: block;
  position: static;
  z-index: 86;

}
#box {
  position: relative;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: rgba(149,207,37,1);
  clear: none;
  display: block;
  margin-left: 106px;
  margin-top: 8px;
}

#Something {
  text-align: center;
  position: absolute;
  left: 282px;
  top: 20px;
  overflow: visible;
  width: 188px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #CAC8C8;
  margin-bottom: 24px;
}
#Someone {
  position: absolute;
  left: 282px;
  top: 24px;
  overflow: visible;
  width: 188px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  color: rgba(224,224,224,1.00);
}

.boxshape {
filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));

  position: absolute;
  overflow: visible;
  width: 70px;
  height: 67px;
  left: 0px;
  top: 6px;
  margin-top: -43px;
  margin-left: -7px;
}

.recshape {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
  position: absolute;
  overflow: visible;
  width: 84px;
  height: 55px;
  margin-left: 102px;
  margin-top: 7px;
}
#art {
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: rgba(149,207,37,1);
  margin-left: 32px;
  margin-top: -4px;
}
#is {
  opacity: 0.81;
  position: absolute;
  overflow: visible;
  width: 18px;
  white-space: nowrap;
  text-align: left;
  font-family: Artifakt Element;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: rgba(149,207,37,1);
  margin-left: 91px;
  margin-top: 10px;
}